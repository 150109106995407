import React, { useMemo } from "react"
import { useLocation } from "@reach/router"
import LoginForm from "app/LoginForm"
import { SEO } from "components/SEO"
import SignedOutWrapper from "app/SignedOutWrapper"
import { queryResolver } from "utils/queryResolver"

function Login({ ...props }) {
  const location = useLocation()

  const urlParams = useMemo(() => {
    if (location.search) {
      return queryResolver(location.search)
    }
  }, [location])

  return urlParams?.token && urlParams?.companyID ? (
    <>
      <SEO title="Login" slug="/" />
      <LoginForm />
    </>
  ) : (
    <SignedOutWrapper>
      <SEO title="Login" slug="/" />
      <LoginForm />
    </SignedOutWrapper>
  )
}

export default Login

Login.defaultProps = {}

Login.propTypes = {}
